"use client";

import { usePathname } from "next/navigation";
import DocDetails from "@/components/documentcenter/DocDetails";
import DocumentCenterLandingPage from "./layout";
import TutorialDetails from "@/components/tutorials/TutorialDetails";
import { _404 } from "@/components/not-found/404";

export const DocNotFound = () => {
  const pathName = usePathname();
  const params = pathName?.match(/^.*docs\/([A-Za-z-]+)\/(.*)/);

  const _renderDetails = (docType?: string, docId?: string) => {
    switch (params?.[1]) {
      case "document-detail":
        return docType && docId ? (
          <DocDetails slug={{ docType, docId }} />
        ) : (
          _404
        );
      case "tutorials":
        return docId ? <TutorialDetails docId={docId} /> : _404;
      default:
        return _404;
    }
  };

  if (params) {
    const [docType, docId] = params[2].split("/");
    return (
      <DocumentCenterLandingPage>
        {_renderDetails(docType, docId)}
      </DocumentCenterLandingPage>
    );
  }
  return _404;
};
