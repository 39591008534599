"use client";

import { usePathname } from "next/navigation";
import DocumentCenterLandingPage from "./layout";
import ApiDetails from "@/components/api-library/ApiDetails";
import { _404 } from "@/components/not-found/404";

export const ApiNotFound = () => {
  const pathName = usePathname();
  const params = pathName?.split("/apis/detail-page/");

  if (params) {
    const [docType, docId] = params[1].split("/");
    return docType && docId ? (
      <DocumentCenterLandingPage>
        <ApiDetails slug={{ docType, docId }} />
      </DocumentCenterLandingPage>
    ) : (
      _404
    );
  }
  return _404;
};
