"use client";

import { APIDetailPage } from "@/components";
import { getApiLibraryDocument } from "@/prismic-api/api-library-service";
import { getAppPrismicClient } from "@/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { _404 } from "../not-found/404";

const ApiDetails = (props: { slug: { docType: string; docId: string } }) => {
  const { docType, docId } = props.slug;
  const prismicClient = useSelector(getAppPrismicClient);
  const [data, setData] = useState<
    Awaited<ReturnType<typeof getApiLibraryDocument>> | null | "404"
  >(null);

  // Fetch api document data from prismic
  useEffect(() => {
    (async () => {
      try {
        const res = await getApiLibraryDocument(prismicClient, docType, docId);
        setData(res);
      } catch (err) {
        setData("404");
      }
    })();
  }, [docId, docType]);

  if (data === "404") {
    return _404;
  }

  return data ? <APIDetailPage home={data} /> : null;
};

export default ApiDetails;
