import { Box } from "@chakra-ui/layout";

export const _404 = (
  <Box
    sx={{
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 20
    }}
  >
    404 page not found
  </Box>
);