"use client";

import { useEffect, useState } from "react";
import TutorialsDetailPage from "@/components/tutorials/detail-page/TutorialsDetailPage";
import { DocumentTypes } from "@/utils/constants";
import { useSelector } from "react-redux";
import { getAppPrismicClient } from "@/store";
import { getDocCenterDocument } from "@/prismic-api/document-service";
import { _404 } from "../not-found/404";

export default function TutorialDetails(props: { docId: string }) {
  const prismicClient = useSelector(getAppPrismicClient);
  const [data, setData] = useState<
    Awaited<ReturnType<typeof getDocCenterDocument>> | null | "404"
  >(null);

  // Fetch api document data from prismic
  useEffect(() => {
    (async () => {
      try {
        const res = await getDocCenterDocument(
          prismicClient,
          DocumentTypes.Pattern,
          props.docId
        );
        setData(res);
      } catch (err) {
        setData("404");
      }
    })();
  }, [props.docId]);

  if (data === "404") {
    return _404;
  }

  return data ? <TutorialsDetailPage home={data} /> : null;
}
