"use client";

import "./APILibrarySideMenu.scss";
import { useEffect, useState } from "react";
import { MultiTieredSideMenuDataModel } from "@/components/common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { useAPILibSideMenuData } from "@/app/apis/providers";
import { usePathname, useSearchParams } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { VerticalStackMenu } from "@finxact/finxact-shared-ui";
import Link from "next/link";
import { useIsPermitted } from "@/utils/common";

const APILibrarySideMenu = () => {
  const [selectedElements, setSelectedElements] = useState<
    MultiTieredSideMenuDataModel[]
  >([]);
  const data = useAPILibSideMenuData();
  const pathName: any = usePathname()?.replace(/\/$/, "");
  const urlValue = window.location.href.replace(/^.*\/\/[^\/]+/, "");
  const isPermitted = useIsPermitted();

  const docId = useSearchParams()?.get("docId");
  const [selectedUID, setSelectedUID] = useState(docId ?? pathName);
  const menuData = data?.docCenterNavigationItems[0]?.childrens;

  const handleSelection = (
    locData: MultiTieredSideMenuDataModel[],
    selectedUID: string | undefined
  ) => {
    locData.map((item: MultiTieredSideMenuDataModel) => {
      if (item.uid === selectedUID) {
        item.isSelected = true;
      } else {
        handleSelection(item.childrens, selectedUID);
        item.isSelected = item.childrens.filter((i) => i.isSelected).length > 0;
      }
    });
    return locData;
  };

  useEffect(() => {
    const items = handleSelection(
      selectedElements.length === 0
        ? data.docCenterNavigationItems
        : selectedElements,
      selectedUID
    );
    setSelectedElements([...items]);
  }, [selectedUID, data.loading]);

  useEffect(() => {
    //Selecting default sidebar if user selects api from topmenu bar
    if (pathName === ROUTE_BASE_URL.API_LIBRARY && pathName !== selectedUID) {
      setSelectedUID(ROUTE_BASE_URL.API_LIBRARY);
    }
  }, [pathName]);

  return (
    <div className="h-100">
      <VerticalStackMenu
        menuData={menuData}
        location={urlValue}
        parent="fast"
        isPermitted={isPermitted}
        nextRouterLinkComponent={Link}
      />
    </div>
  );
};

export default APILibrarySideMenu;
