"use client";
import { usePathname } from "next/navigation";
import { DocNotFound } from "./docs/DocNotFound";
import { ApiNotFound } from "./apis/ApiNotFound";
import { _404 } from "@/components/not-found/404";

export default function NotFound() {
  const pathName = usePathname();
  const fastModule = pathName?.split("/");
  if (fastModule) {
    switch (fastModule[1]) {
      case "docs":
        return <DocNotFound />;
      case "apis":
        return <ApiNotFound />;
      default:
        return _404;
    }
  }
}
