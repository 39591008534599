import { useEffect, useState } from "react";
import { MultiTieredSideMenuDataModel } from "@/components/common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { useData } from "../../../app/docs/providers";
import { usePathname, useSearchParams } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { VerticalStackMenu } from "@finxact/finxact-shared-ui";
import Link from "next/link";
import { useIsPermitted } from "@/utils/common";

const DocumentCenterSideMenu = () => {
  const [selectedElements, setSelectedElements] = useState<
    MultiTieredSideMenuDataModel[]
  >([]);
  const data = useData();
  const pathName = usePathname()?.replace(/\/$/, "");
  const urlValue = window.location.href.replace(/^.*\/\/[^\/]+/, "");
  const isPermitted = useIsPermitted();

  const docId: any = useSearchParams()?.get("docId");
  const [selectedUID, setSelectedUID] = useState(docId ?? pathName);
  const menuData = data?.docCenterNavigationItems[0]?.childrens;

  const handleSelection = (
    locData: MultiTieredSideMenuDataModel[],
    selectedUID: string | undefined
  ) => {
    locData.map((item: MultiTieredSideMenuDataModel) => {
      if (item.uid === selectedUID) {
        item.isSelected = true;
      } else {
        handleSelection(item.childrens, selectedUID);
        item.isSelected = item.childrens.filter((i) => i.isSelected).length > 0;
      }
    });
    return locData;
  };

  useEffect(() => {
    const items = handleSelection(
      selectedElements.length === 0
        ? data.docCenterNavigationItems
        : selectedElements,
      selectedUID
    );
    setSelectedElements([...items]);
  }, [selectedUID, data.loading]);

  useEffect(() => {
    //Selecting default sidebar if user selects doc from topmenu bar
    if (
      pathName === ROUTE_BASE_URL.DOCUMENT_CENTER &&
      pathName !== selectedUID
    ) {
      setSelectedUID(ROUTE_BASE_URL.DOCUMENT_CENTER);
    }
  }, [pathName]);

  return (
    <div className="h-100">
      <VerticalStackMenu
        menuData={menuData}
        location={urlValue}
        parent="fast"
        nextRouterLinkComponent={Link}
        isPermitted={isPermitted}
      />
    </div>
  );
};

export default DocumentCenterSideMenu;
